import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import CreateSelf from 'components/users/self/create';
import { t } from 'src/i18n/config';

const CreatePage = (props) => {
  const { location = {}, bindingPhoneNumber } = props;
  const { state } = location;
  const user = props?.user || state?.user || {};

  return (
    <Layout>
      <Helmet>
        <title>{t('basicInformationSetup')}</title>
      </Helmet>
      <CreateSelf
        user={user}
        idNumLocked={user.idNumLocked}
        bindingPhoneNumber={bindingPhoneNumber}
      ></CreateSelf>
    </Layout>
  );
};

export default CreatePage;
