import React, { useEffect, useState } from 'react';
import { LineContext } from 'components/context';
import UsersCreatePage from 'pages/users/create';
import { setupLiff } from 'src/lib/liff';
import { fetchAndSaveAuthToken, getUserInfo } from 'services/user.service';
import { setAuth } from 'src/services/utils';

export default function Home(props) {
  const [message, setMessage] = useState({});
  const [user, setUser] = useState();
  const [bindingPhoneNumber, setBindingPhoneNumber] = useState(false);
  const [token, setToken] = useState();
  useEffect(() => {
    setupLiff()
      .then(async (res) => {
        setMessage(res);
        const { userId, liffId } = res;

        const t = await fetchAndSaveAuthToken({ userId, liffId });
        setAuth({ token: t, liffId, userId });
        setToken(t);
        getUserInfo().then((user) => {
          setBindingPhoneNumber(user.Phone);
          if (user.patients.length !== 0) {
            const self = user.patients.find((u) => u.isSelf);
            setUser(self);
          }
        });
      })
      .catch(async (err) => {
        setMessage(err);
      });
  }, []);

  if (token) {
    return (
      <LineContext.Provider value={message}>
        <UsersCreatePage
          {...props}
          {...message}
          user={user}
          bindingPhoneNumber={bindingPhoneNumber}
        ></UsersCreatePage>
      </LineContext.Provider>
    );
  }
  return null;
}
