import React, {
  useMemo,
  useReducer,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Button } from '../../1TalkDesign/Buttons';
import Footer from '../..//Footer';
import BirthSelector from '../../1TalkDesign/BirthSelector';
import Radios from '../../1TalkDesign/Radios';
import { TextFieldSecondary } from '../..//1TalkDesign/Textfield';
import { createSelf, updatePatient } from '../../../services/user.service';
import {
  studIdNumberIdentify,
  idTypes,
  genderOptions,
} from '../../../utils/user';
import { getURLSearchParamByKey } from 'src/utils';
import { t } from 'src/i18n/config';

const InputField = {
  NAME: 'name',
  ID_NUM: 'idNum',
};

const TextField = styled(TextFieldSecondary)`
  flex: 1;
  margin-top: 16px;
`;

const TopTextField = styled(TextFieldSecondary)`
  flex: 1;
`;

const RadioGroup = styled(Radios)`
  margin-top: 16px;
`;

const InputContainer = styled.div`
  display: flex;
  margin: 24px 16px 0 16px;
  flex-direction: column;
`;

const Description = styled.div`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: 1rem;
  line-height: 1.54;
  letter-spacing: 0.09px;
`;

const CreateButoon = styled(Button)`
  width: calc(100% - 16px);
  margin: 12px 16px;
`;
const CFooter = styled(Footer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const defaultState = {
  info: {
    name: '',
    birth: '', // format -> '1990/01/01'
    idNum: '',
    idType: 'twID',
    gender: 'M',
  },
  errInput: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setname':
      return {
        ...state,
        info: {
          ...state.info,
          name: action.payload,
        },
      };
    case 'setidNum':
      return {
        ...state,
        info: {
          ...state.info,
          idNum: (action.payload || '').toUpperCase(),
        },
      };
    case 'setbirth':
      return {
        ...state,
        info: {
          ...state.info,
          birth: action.payload,
        },
      };
    case 'gender':
      return {
        ...state,
        info: {
          ...state.info,
          gender: action.payload,
        },
      };
    case 'userChanged':
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload,
        },
      };
    case 'validate':
      return {
        ...state,
        errInput: action.payload,
      };
    case 'idType':
      return {
        ...state,
        info: {
          ...state.info,
          idType: action.payload,
        },
      };
    default:
      break;
  }
};

const isValid = (errInput) => {
  if (Object.keys(errInput).length !== 0) {
    return false;
  }
  return true;
};

const Create = ({ user, idNumLocked, bindingPhoneNumber }) => {
  const birthSelectorRef = useRef();
  const showPassport = getURLSearchParamByKey('passport') === 'true';
  const generateMemberID =
    getURLSearchParamByKey('generateMemberID') === 'true';
  const goBack = getURLSearchParamByKey('goBack');

  const [state, dispatch] = useReducer(
    reducer,
    Object.keys(user).length > 0
      ? { info: { ...defaultState.info, ...user }, errInput: {} }
      : defaultState
  );

  const idOptions = useMemo(() => {
    if (idNumLocked) {
      return idTypes.filter(({ value }) => value === state.info.idType);
    }
    return showPassport
      ? idTypes
      : idTypes.filter(({ value }) => value !== 'pdID');
  }, [showPassport, idNumLocked, state.info.idType]);

  const selectedIdType = useMemo(() => {
    return idOptions.find(({ value }) => value === state.info.idType) || {};
  }, [idOptions, state.info.idType]);

  const checkValidation = useCallback(
    (user, idType) => {
      const errInput = {};
      if (
        !generateMemberID &&
        !studIdNumberIdentify(state.info.idNum, idType)
      ) {
        errInput[InputField.ID_NUM] = `${t('enterCorrectIdType')}${
          selectedIdType.label
        }`;
      }
      if (state.info.name === '') {
        errInput[InputField.NAME] = t('nameFormatError');
      }
      if (state.info.birth === '' || state.errInput['birth']) {
        const birthError = birthSelectorRef.current.validate();
        errInput['birth'] = birthError;
      }
      dispatch({ type: 'validate', payload: errInput });

      return errInput;
    },
    [
      generateMemberID,
      selectedIdType.label,
      state.errInput,
      state.info.birth,
      state.info.idNum,
      state.info.name,
    ]
  );

  const successCallback = () => {
    if (goBack) {
      window.location.replace(document.referrer);
    } else {
      navigate('/users/create/success', { replace: true });
    }
  };

  const handleClick = async () => {
    const errInput = checkValidation(user, state.info.idType);
    if (!isValid(errInput)) return;
    if (generateMemberID && !state.info.idNum) {
      let phone = user.phone || bindingPhoneNumber;
      if (phone.startsWith('+886')) {
        phone = phone.replace('+886', '0');
      }
      state.info.idType = 'memberID';
      state.info.idNum = state.info.name + phone;
    }
    if (user.id) {
      try {
        await updatePatient({
          id: user.id,
          ...state.info,
        });
        successCallback();
      } catch (error) {
        alert(error.message);
      }

      return;
    }
    createSelf(state.info)
      .then(() => {
        successCallback();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleChange = (e) => {
    const changed = e.currentTarget.name;
    dispatch({ type: 'set' + changed, payload: e.currentTarget.value });
  };

  const handleBirthChange = (value) => {
    dispatch({ type: 'setbirth', payload: value });
  };

  const handleIDTypeChange = (idType) => {
    dispatch({ type: 'idType', payload: idType });
  };

  const handleGenderChange = (gender) => {
    dispatch({ type: 'gender', payload: gender });
  };

  useEffect(() => {
    if (user) {
      dispatch({ type: 'userChanged', payload: user });
    }
  }, [user]);

  return (
    <>
      <InputContainer>
        <Description>{t('serviceDataDescription')}</Description>
        {!generateMemberID && (
          <>
            <RadioGroup
              options={idOptions}
              defaultValue={state.info.idType}
              onChange={handleIDTypeChange}
            />
            <TopTextField
              name={InputField.ID_NUM}
              disabled={idNumLocked}
              onChange={handleChange}
              value={state.info.idNum}
              helperText={state.errInput[InputField.ID_NUM]}
              error={!!state.errInput[InputField.ID_NUM]}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              placeholder={selectedIdType.label}
            />
          </>
        )}
        <TextField
          name={InputField.NAME}
          onChange={handleChange}
          value={state.info.name}
          placeholder={t('enterYourName')}
          helperText={state.errInput[InputField.NAME]}
          error={!!state.errInput[InputField.NAME]}
        />
        <BirthSelector
          ref={birthSelectorRef}
          birth={state.info.birth}
          onChange={handleBirthChange}
        ></BirthSelector>
        {(selectedIdType.value === 'pdID' || generateMemberID) && (
          <RadioGroup
            options={genderOptions}
            defaultValue={state.info.gender}
            onChange={handleGenderChange}
          />
        )}
      </InputContainer>
      <CFooter>
        <CreateButoon onClick={handleClick}>{t('confirm')}</CreateButoon>
      </CFooter>
    </>
  );
};

Create.proptTypes = {
  idNumLocked: PropTypes.string,
};

export default Create;
